import React from "react";
import PropTypes from "prop-types";

import { HeaderCard, IconButton, InputCheck, InputCheckbox, InputDateTime, InputText, Form, PractitionerButton, RegExp } from "@rdcs/dap-front-library";

import { isLocalAPI } from "../../utils/utils";
import API from "../../utils/API";

const patientFirstnameFormat = {
    pattern: RegExp.name,
    message: "Le prénom doit être constitué uniquement de lettre ou de tiret.",
};

const patientLastnameFormat = {
    pattern: RegExp.name,
    message: "Le nom doit être constitué uniquement de lettre ou de tiret.",
};

const BreakScoreQuestionnaireForm1 = ({
    onSubmit,
    setNonInsulino,
    patient: { firstName = "", lastName = "", birthDate = null, consent = null, allowDataUsageForStudy = false, insulino = false },
}) => {
    const onSubmitCallback = React.useCallback(
        (form) => {
            setNonInsulino(form.insulino);
            onSubmit({
                ...form,
                consent: null,
                practitioner: null,
                email: null,
                phoneNumber: null,
            });
        },
        [onSubmit, isLocalAPI ? null : consent]
    );

    return (
        <div className="breakScoreQuestionnaire__container">
            <Form onSubmit={onSubmitCallback}>
                <HeaderCard
                    title="Recueillir le consentement"
                    subtitle="Pour cela, assurez-vous de lire le texte suivant au patient, puis de lui envoyer un email de confirmation afin de prouver le recueil du consentement. "
                    icon="consentement"
                >
                    <p className="ml-8" style={{ fontSize: "12px", maxWidth: "950px" }}>
                        Dans le cadre du programme Aprodia initié par le responsable de traitement timkl, nous sommes amenés à traiter certaines de vos données
                        personnelles, et notamment vos données de santé pendant la durée de votre compte augmentée d'un an. Un email vous sera envoyé vous
                        détaillant la manière dont vos données sont traitées, et vous aurez la possibilité de contacter le DPO pour toute demande à l'adresse
                        dpo.timkl@timkl.fr. Dans ce cadre, nous allons réaliser ensemble un questionnaire. Nous donnez-vous votre accord à la collecte et au
                        partage de vos données médicales entre timkl et un membre de votre équipe de soins ? Consentez-vous également à la collecte et au
                        partage de vos données médicales et de contact entre les acteurs médico-sociaux pouvant concourir à votre accompagnement et timkl ? Pour
                        finir, souhaitez-vous être contacté dans le cadre de réalisation d'études ?
                    </p>
                </HeaderCard>
                <div className="py-4 px-8">
                    <section>
                        <h2 className="title-3 mb-2">Informations personnelles du patient</h2>
                        <div className="grid grid__padding-25">
                            <div className="d-flex">
                                <p className="ml-3 mr-4 mt-1">Le patient est-il insulino-traité ?</p>
                                <InputCheck name="insulino" defaultValue={false} checked={insulino} />
                            </div>
                            <InputText
                                label="Nom"
                                placeholder="Saisissez le nom du patient"
                                required
                                name="lastName"
                                defaultValue={lastName}
                                format={patientLastnameFormat}
                                containerClassName="col-sm-30 col-xs-100"
                            />
                            <InputText
                                label="Prénom"
                                placeholder="Saisissez le prénom du patient"
                                required
                                name="firstName"
                                defaultValue={firstName}
                                format={patientFirstnameFormat}
                                containerClassName="col-sm-30 col-xs-100"
                            />
                        </div>
                        <div className="grid grid__padding-25">
                            <InputDateTime
                                label="Date de naissance du patient"
                                placeholder="Saisissez la date de naissance du patient"
                                maxDate={new Date()}
                                required
                                name="birthDate"
                                defaultValue={birthDate && new Date(birthDate)}
                                className="col-sm-25 col-xs-100"
                            />
                            <PractitionerButton
                                name="identifiedBy"
                                searchAction={(params) => API.searchInRpps(params)}
                                icon="stethoscope"
                                required
                                secondary
                                labels={{
                                    title: "Quel est le PdS qui a identifié ce patient ? ",
                                    buttonModal: "Quel est le PdS qui a identifié ce patient ? ",
                                    buttonSave: "Sélectionner",
                                }}
                                buttonClassName="timkl__color--blue-important mt-5"
                            />
                        </div>
                    </section>

                    <InputCheckbox
                        className="mt-4"
                        label="Sauvegarder les réponses et informations personnelles du patient à des fins d'étude"
                        defaultChecked={allowDataUsageForStudy}
                        name="allowDataUsageForStudy"
                    />

                    <div className="txtRight mt-4">
                        <IconButton className="button__primaryButton" type="submit" icon="arrow-right" right>
                            Enregistrement
                        </IconButton>
                    </div>
                </div>
            </Form>
        </div>
    );
};

BreakScoreQuestionnaireForm1.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    setNonInsulino: PropTypes.func.isRequired,
    patient: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        birthDate: PropTypes.string,
        consent: PropTypes.string,
        allowDataUsageForStudy: PropTypes.bool,
        insulino: PropTypes.bool,
    }).isRequired,
};

export default BreakScoreQuestionnaireForm1;
